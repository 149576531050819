module.exports = [{
      plugin: require('../plugins/gatsby-plugin-incorrect-locale/gatsby-browser.js'),
      options: {"plugins":[],"localeArray":["vi-vn"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/prod-mainsite-lol/locale","languages":["vi-vn"],"defaultLanguage":"en-us","redirect":true},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["vi-vn"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5D2MSF9","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/gatsby"},
    }]
